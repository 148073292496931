import { hash } from 'ohash'
import { useStatamicPreviewMode } from '~/composables/useStatamicPreviewMode'

export const useCachedGql = (...args: any[]) => {
  const toReactive = (v: any) => (v && isRef(v) ? v : reactive(v))
  const options =
    (typeof args?.[0] !== 'string' && 'options' in args?.[0]
      ? args[0].options
      : args[2]) ?? {}
  const operation =
    (typeof args?.[0] !== 'string' && 'operation' in args?.[0]
      ? args[0].operation
      : args[0]) ?? undefined
  const variables =
    (typeof args?.[0] !== 'string' && 'variables' in args?.[0]
      ? toReactive(args[0].variables)
      : args[1] && toReactive(args[1])) ?? undefined
  if (variables) {
    options.watch = options.watch || []
    options.watch.push(variables)
  }
  const key = `gql:data:${hash({ operation, variables })}`

  const revalidateHeader = useRuntimeConfig().public.VERCEL_BYPASS_TOKEN

  let previewModeEnabled = ref(false)
  let previewToken = ''
  let frontendUri = undefined
  if (process.server) {
    // If the request is part of the initial SSR, provide the URL to the api
    frontendUri = useRoute().fullPath
    console.log(frontendUri)
  }

  const { enabled, state } = useStatamicPreviewMode()
  previewModeEnabled = enabled
  previewToken = (state.token as string | undefined) ?? ''

  if (previewModeEnabled.value) {
    return useAsyncGql(...args)
  }

  return useAsyncData(
    key,
    () =>
      $fetch(
        `/api/graphql${
          previewModeEnabled.value ? `?token=${previewToken}` : ''
        }`,
        {
          // pass variables as JSON (normal query params would not be typed)
          query: {
            _variables: JSON.stringify(unref(variables)),
            _operation: operation,
            _frontendUri: frontendUri,
          },
          ...(previewModeEnabled.value
            ? {
                headers: {
                  'x-prerender-revalidate':
                    typeof revalidateHeader === 'string'
                      ? revalidateHeader
                      : '',
                },
              }
            : {}),
        }
      ),
    options
  )
}
